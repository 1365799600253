<template>
    <v-sheet class="pa-5">

        <stores-list></stores-list>

    </v-sheet>

</template>

<script>

    import List from '@/components/stores/List'

    export default {

        name: "List",

        data () {
            return {
                search: '',
                openFilters: false,
            }
        },

        components: {
            'stores-list': List,
        }
    }
</script>

<style lang="scss" scoped>
</style>
