<template>
  <v-dialog
      v-model="dialog"
      max-width="700px"
      persistent
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          color="primary"
          class="mb-2"
          v-bind="attrs"
          v-on="on"
          :disabled="isLoading"
      >
        {{ $t('ecommerce.stores.products.actions.add-product') }}
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        {{ formTitle }}
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text>
        <v-row v-if="!isEditing">
          <v-col class="d-flex justify-start mb-0">
            <div class="pr-5">
              <v-text-field
                  v-model="sku"
                  :label="$t('ecommerce.stores.products.actions.find-product')"
                  required
                  outlined
                  dense
                  hide-details
              ></v-text-field>
            </div>

            <v-btn class="primary" :disabled="sku.length === 0" :loading="searchingState" @click="findProduct">
              {{ $t('commons.actions.search') }}
            </v-btn>

          </v-col>
        </v-row>

        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
        >
            <v-row>
              <v-col>
                <template v-if="localProduct.id">
                  <divider></divider>
                  <product-details :data.sync="productData"
                                   :is-form="true"
                                   :product="localProduct"
                                   :is-edit="isEditing"
                  ></product-details>

                  <divider></divider>

                </template>
              </v-col>
            </v-row>


            <v-row>
              <v-col>
                <dialog-buttons
                    :display-confirm-button="hasProduct"
                    :valid="valid"
                    :is-loading="isPatchingProducts"
                    @save="storeProduct"
                    @close="close"
                ></dialog-buttons>
              </v-col>
            </v-row>
        </v-form>

      </v-card-text>
    </v-card>

  </v-dialog>
</template>

<script>

import DialogButtons from "@/components/form/DialogButtons";
import ProductDetails from "@/components/products/_partials/ProductDetails";
import {mapGetters} from "vuex";
import {EventBus} from "@/event-bus";
import {validationRules} from "@/mixins/validationRules";
import clonedeep from "lodash.clonedeep";

export default {
  name: "ProductForm",

  components: {
    DialogButtons,
    ProductDetails
  },

  mixins: [validationRules],

  data: () => ({
    productData: {},
    localProduct: {},
    sku: '',
    isEditing: false,
    dialog: false,
    sending: false,
    valid: false,
  }),

  mounted() {

    let self = this

    this.localProduct = clonedeep(this.product)

    EventBus.$on('edit-store-product', function (product) {
      console.log('on edit-store-product product ' , product)
      self.localProduct = product
      self.isEditing = true
      self.dialog = true
    })
  },

  computed: {
    ...mapGetters({
      isLoading: 'product/loadingState',
      isPatchingProducts: 'store/isPatchingProducts',
      searchingState: 'product/searchingState',
      store: 'store/getStore',
      product: 'product/getProduct',
      products: 'product/getProducts',
    }),
    hasProduct() {
      return (Object.keys(this.localProduct).length > 0)
    },
    formTitle() {
      return (this.isEditing === true) ? this.$t('ecommerce.stores.products.edit.title') : this.$t('ecommerce.stores.products.add.title')
    },
  },

  methods: {

    async findProduct() {

      // verifico se il prodotto è già presente nella lista di quelli in vendita
      if (this.store.products.includes(this.sku)) {
        this.$store.dispatch('store/getProductBySku', this.sku)
        this.localProduct = this.product
        this.isEditing = true
      } else {

        await this.$store.dispatch('product/findProduct', this.sku)
            .then(resp => {
              this.localProduct = this.product
            })
            .catch(err => {
              console.log('create error err ', err)
            })
      }
    },

    async storeProduct() {
      await this.$store.dispatch('store/storeProductInfo', {
        store_id: this.store.id,
        sku: this.product.sku_code,
        productData: this.productData
      })
          .then(resp => {
            console.log('then close dialog resp ', resp)
            this.close()
          })
          .catch(err => {
            console.log('create error err ', err)
          })
    },

    close() {
      this.dialog = false
      this.valid = true
      this.sku = ''
      this.localProduct = {}
      this.$refs.form.reset()
      this.$store.dispatch('product/resetProduct')
      this.$nextTick(() => {
        this.isEditing = false
      })
    },
  },

  watch: {
    sku(val) {
      if (val.length === 6) {
        this.findProduct()
      }
    }
  }
}
</script>

<style scoped>
.bordered {
  border: 1px solid #dddddd;
}

</style>