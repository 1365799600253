<template>
  <!--  Aggiungi un prodotto:-->
  <!--  - campo ricerca tramite sku-->
  <!--  - conferma selezione:-->
  <!--  - campo stock / quantità (items_stock int)-->
  <!--  - campo prezzo (item_price da parent)-->
  <!--  - prezzo pezzo singolo / a peso (price_by_weight boolean)-->
  <!--  - unità peso (weights)-->
  <div>

    <div class="mt-5">
      <product-form></product-form>
    </div>

    <v-divider></v-divider>

    <v-card flat class="mt-5 d-flex">
      <v-text-field
          v-model="search.text"
          append-icon="mdi-magnify"
          :label="$t('commons.actions.search')"
          outlined
          dense
          class="mr-5"
      ></v-text-field>

      <v-select
          v-model="search.saleable"
          :label="$t('commons.fields.status')"
          :items="saleableStatuses"
          item-text="name"
          item-value="value"
          outlined
          dense
      ></v-select>

    </v-card>


    <v-data-table
        :loading="loadingProductsState"
        :loading-text="$t('commons.loading')"
        :headers="headers"
        :page.sync="page"
        :items="products"
        :items-per-page="itemsPerPage"
        :hide-default-footer="true"
        class="elevation-0 clickable"
        @page-count="pageCount = $event"
        :expanded.sync="expanded"
        show-expand
        :single-expand="true"
        :search="search.text"
    >

      <template v-slot:item.name="{ item }">
        <b>{{ item.name }}</b>
      </template>

      <template v-slot:item.saleable="{ item }">
        <b>
          <check :val="item.saleable"></check>
        </b>
      </template>

      <template v-slot:item.price="{ item }">
        {{ $n(item.price, 'currency') }}
      </template>

      <template v-slot:item.image="{ item }">
        <v-img
            v-if="item.image"
            :src="item.image.url"
            contain
            width="100"
            class="py-5"
        />

      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-container class="grey lighten-5">
            <product-details :product="item"></product-details>
          </v-container>
        </td>
      </template>

    </v-data-table>

    <v-divider></v-divider>

    <div class="text-center py-3">
      <v-pagination
          v-model="page"
          :length="pageCount"
      ></v-pagination>
    </div>

  </div>
</template>

<script>
import {mapGetters} from "vuex";
import ProductForm from "@/components/stores/_partials/ProductForm";
import ProductDetails from "@/components/products/_partials/ProductDetails";
import Check from "@/components/commons/Check";

export default {
  name: "List",

  components: {
    ProductForm,
    Check,
    ProductDetails
  },


  data() {
    return {
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      search: {
        text: '',
        saleable: ''
      },
      headers: [
        {text: this.$t('products.fields.sku_code'), value: 'sku_code', sortable: false, width: '8%'},
        {text: this.$t('products.fields.title'), value: 'title', sortable: false},
        {
          text: this.$t('ecommerce.stores.products.fields.saleable'),
          value: 'saleable',
          sortable: false,
          width: '10%',
          align: 'center',
          filter: value => {
            if (this.search.saleable === '') return true
            return value === this.search.saleable
          },
        },
        {text: this.$t('products.fields.price'), value: 'price', sortable: false, width: '8%'},
        {text: this.$t('products.fields.stock'), value: 'stock', sortable: false, width: '8%'},
        {text: this.$t('products.fields.image'), value: 'image', sortable: false, width: '180px'},
        {text: '', value: 'data-table-expand'},
      ],
      expanded: [],
      saleableStatuses: [
        {
          name: this.$t('commons.fields.all'),
          value: ''
        },
        {
          name: this.$t('ecommerce.stores.products.fields.saleable'),
          value: 1
        },
        {
          name: this.$t('ecommerce.stores.products.fields.not-saleable'),
          value: 0
        },
      ]
    }
  },

  mounted() {
    this.getProducts()
  },

  computed: {
    ...mapGetters({
      products: 'store/getProducts',
      isAdmin: 'auth/isAdmin',
      isLoading: 'store/loadingProductsState',
      loadingProductsState: 'store/loadingProductsState',
      store: 'store/getStore'
    }),
  },

  methods: {
    async getProducts() {
      await this.$store.dispatch('store/fetchProducts', this.store.id)
    }
  }
}
</script>

<style scoped>

</style>
