<template>
  <div>
    <store-details></store-details>

    <ecommerce></ecommerce>
  </div>
</template>

<script>

import StoreDetails from "@/components/stores/StoreDetails";
import Ecommerce from "@/components/stores/_partials/Ecommerce";

export default {
  components: {StoreDetails, Ecommerce}
}
</script>

<style scoped>

</style>