<template>
  <div>
    <v-data-table
        :loading="isLoading"
        :loading-text="$t('commons.loading')"
        :headers="headers"
        :items="stores"
        :items-per-page="pagination.per_page"
        :hide-default-footer="true"
        class="elevation-0 clickable"
        @click:row="details"
        disable-pagination
    >

      <template v-slot:item.name="{ item }">
        <b>{{ item.name }}</b>
      </template>

      <template v-slot:item.click_and_collect="{ item }">
        <b>
          <check :val="item.click_and_collect"></check>
        </b>
      </template>
    </v-data-table>

    <v-divider></v-divider>

    <div class="text-center py-3">
      <v-pagination
          v-model="pagination.current"
          :length="pagination.total"
          :total-visible="5"
          @input="getStores()"
          :disabled="isLoading"
      ></v-pagination>
    </div>

  </div>
</template>

<script>
import {mapGetters} from "vuex";
import Check from "@/components/commons/Check";

export default {
  name: "List",

  data() {
    return {
      storeFilter: '',
      filterMenu: false,
      filters: {
        exact: {},
        like: {}
      },
      headers: [
        {text: '#', value: 'sigla', sortable: false, width: '8%'},
        {text: this.$t('stores.fields.name'), value: 'name', sortable: false},
        {text: this.$t('stores.fields.city'), value: 'city', sortable: false,},
        {
          text: this.$t('stores.fields.click_and_collect'),
          value: 'click_and_collect',
          sortable: false,
          width: '5%',
          align: "center"
        }
      ]
    }
  },

  components: {
    Check
  },

  mounted() {
    this.getStores()
  },

  methods: {
    getStores() {
      this.$store.dispatch('store/fetchStores', this.filters)
    },

    details(v) {
      if (this.isAdmin || this.isCC) {
        this.$router.push({
          name: 'stores-details',
          params: {
            id: v.id
          }
        })
      }
    },

    applyFilter(e) {
      this.$store.dispatch('store/reset')
      this.storeFilter = this.$store.getters['settings/getStoreById'](e)
      this.getStores()
    },

    removeStoreFilter(event) {
      delete this.filters.exact['id']
      this.storeFilter = ''
      this.getStores()
      event.stopPropagation()
    }
  },

  computed: {
    ...mapGetters({
      isAdmin: 'auth/isAdmin',
      isCC: 'auth/isCcUser',
      isLoading: 'store/loadingState',
      stores: 'store/getStores',
      pagination: 'store/getPagination'
    }),
  }
}
</script>

<style scoped>

</style>
