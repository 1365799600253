<template>
  <div>

    <div v-if="isLoading" align="center" class="py-5">
      <v-progress-circular indeterminate size="32"></v-progress-circular>
    </div>

    <template v-if="!isLoading">
      <v-btn  v-if="isAdmin" color="primary" class="my-5" @click="$router.back()">{{ $t('commons.actions.backToList') }}</v-btn>

      <v-card>
        <v-card-title class="d-flex justify-space-between">
          <h3>{{ store.name }}</h3>
          <div v-if="!isLoading">
            <v-chip label color="success" v-if="store.click_and_collect" outlined class="font-weight-bold">
              <check :d-block="false" :val="true" class="mr-2"></check>
              {{ $t('ecommerce.stores.details.click_and_collect') }}
            </v-chip>
            <v-chip label color="error" v-else outlined class="font-weight-bold">
              <check :d-block="false" :val="false" class="mr-2"></check>
              {{ $t('ecommerce.stores.details.click_and_collect') }}
            </v-chip>
          </div>
        </v-card-title>
        <v-card-text>
          <v-divider class="mb-5"></v-divider>

          <v-row>
            <v-col class="font-weight-medium">
              <v-card flat>
                <v-card-title>
                  <h4>{{ $t('stores.details.address') }}</h4>
                </v-card-title>
                <v-card-text>
                  {{ $t('stores.fields.sigla') }}: <strong>{{ store.sigla }}</strong><br>
                  {{ store.address }}<br>
                  {{ store.zip_code }}, {{ store.city }}
                </v-card-text>
                  
              </v-card>
              <v-card flat v-if="store.qr_code">
                <v-card-title primary-title>
                  Qrcode
                </v-card-title>
                <v-card-text>
                  {{store.qr_code.img.data}}
                  <a :href="store.qr_code.img.url" target="_blank" >
                  <v-img max-width="120px" :src="store.qr_code.img.url"></v-img>
                  </a>
                </v-card-text>
              </v-card>

              <v-card flat>
                <v-card-title>
                  <h4>{{ $t('stores.details.services') }}</h4>
                </v-card-title>
                <v-card-text>
              <span v-for="service in store.services_list" :key="service">
                {{ service }}
                <v-divider></v-divider>
              </span>
                </v-card-text>
              </v-card>

            </v-col>

            <v-col class="d-flex justify-end">
              <v-img v-if="store"
                     max-width="450"
                     :src="'https://maps.googleapis.com/maps/api/staticmap?center=' + store.latitude + ',' + store.longitude + '&markers=color:0x3B190C%7Clabel:V%7C' + store.latitude + ',' + store.longitude + '&zoom=15&size=450x300&maptype=roadmap&key=AIzaSyBTgUNvElTwQk9ux1pDLxK-ai6aSRLcpy4'"></v-img>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </template>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import Check from "@/components/commons/Check";

export default {
  name: "StoreDetails",
  components: {Check},
  mounted() {
    this.fetchStore()
  },

  computed: {
    ...mapGetters({
      isAdmin: 'auth/isAdmin',
      isStoreUser: 'auth/isStoreUser',
      isLoading: 'store/loadingState',
      store: 'store/getStore'
    }),
  },

  methods: {
    fetchStore() {
      if (this.$route.params.id) {
        this.$store.dispatch('store/fetchStore', this.$route.params.id)
      } else {
        this.$store.dispatch('store/fetchStoreByAuthUser')
      }
    }
  },

  beforeDestroy() {
    this.$store.dispatch('store/resetStore')
  },
}
</script>

<style scoped>

</style>