<template>
  <v-card class="mt-10" v-if="store && store.id && (isAdmin || isStoreUser || isCC)">
    <v-card-text class="px-5">
      <v-tabs
          v-model="tab"
          centered
          grow
          color="primary"
      >
        <v-tab>
          {{ $t('stores.details.ecommerce.products') }}
        </v-tab>
        <v-tab>
          {{ $t('stores.details.ecommerce.orders') }}
        </v-tab>
        <v-tab>
          {{ $t('stores.details.ecommerce.options') }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">

        <v-tab-item
            :transition="false"
            :reverse-transition="false"
        >
          <products-list></products-list>
        </v-tab-item>

        <v-tab-item
            :transition="false"
            :reverse-transition="false"
        >
          <order-list :is-store-details="store.store_code"
          ></order-list>
        </v-tab-item>

        <v-tab-item
            :transition="false"
            :reverse-transition="false"
        >
          <options></options>
        </v-tab-item>

      </v-tabs-items>
    </v-card-text>
  </v-card>
</template>

<script>
import {mapGetters} from "vuex";
import ProductsList from "@/components/stores/_partials/ProductsList";
import List from "@/components/ecommerce/List";
import Options from "@/components/stores/_partials/Options";

export default {
  name: "Ecommerce",

  components: {
    ProductsList,
    'order-list': List,
    Options
  },

  data() {
    return {
      tab: 0
    }
  },

  computed: {
    ...mapGetters({
      isAdmin: 'auth/isAdmin',
      isStoreUser: 'auth/isStoreUser',
      isCC: 'auth/isCcUser',
      isLoading: 'store/loadingState',
      store: 'store/getStore'
    }),
  },
}
</script>

<style scoped>

</style>