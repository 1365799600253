<template>

  <div>
    <div v-if="loadingProductsState" align="center" class="py-5">
      <v-progress-circular indeterminate size="32"></v-progress-circular>
    </div>

    <v-row v-else justify="start">
      <v-col md="3">
        <v-img :src="product.image.url_mdpi" max-width="200"></v-img>
      </v-col>
      <v-col>

        <strong>{{ product.title }}</strong><br>
        <span v-html="product.text"></span>

        <divider></divider>

        <template v-if="isForm">
          <v-row>
            <v-col>
              <v-text-field
                  :label="$t('ecommerce.stores.products.fields.items_stock')"
                  v-model="productData.items_stock"
                  :required="true"
                  :requiredRules="requiredRules"
                  outlined
                  dense
                  type="number"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                  :label="$t('ecommerce.stores.products.fields.item_price')"
                  v-model="productData.item_price"
                  :required="true"
                  :requiredRules="requiredRules"
                  outlined
                  dense
              ></v-text-field>
            </v-col>
            <v-col>
              <v-switch
                  class="mt-1"
                  v-model="productData.item_saleable"
                  :label="$t('ecommerce.stores.products.fields.saleable')"
                  inset
                  color="success"
              ></v-switch>

            </v-col>
          </v-row>
        </template>

        <template v-else>
          <strong>{{ $t('ecommerce.stores.products.fields.items_stock') }}</strong>: {{ product.stock }}<br>

          <strong>{{ $t('ecommerce.stores.products.fields.item_price') }}</strong>: {{ $n(product.price, 'currency') }}

          <span v-if="product.price_by_weight === 0">{{ $t('ecommerce.stores.products.fields.by_weight') }}</span>

          <span v-else>{{ $t('ecommerce.stores.products.fields.by_item') }}</span> <br>

          <v-chip label :color="(product.saleable) ? 'success' : 'error'" class="mt-3">
            {{
              (product.saleable) ? $t('ecommerce.stores.products.fields.saleable') : $t('ecommerce.stores.products.fields.not-saleable')
            }}
          </v-chip>

          <divider></divider>

          <div class="text-right">
            <v-btn small color="primary" @click="edit(product)">Modifica</v-btn>
          </div>
        </template>
      </v-col>
    </v-row>
  </div>
</template>

<script>

import clonedeep from "lodash.clonedeep";
import {validationRules} from "@/mixins/validationRules";
import {EventBus} from "@/event-bus";
import {mapGetters} from "vuex";

export default {
  name: "ProductDetails",

  mixins: [validationRules],

  components: {},

  props: {
    product: {
      type: Object,
      required: true
    },
    isForm: {
      type: Boolean,
      default: false
    },
    isEdit: {
      type: Boolean,
      default: false
    }
  },

  data(self) {
    return {
      defaultProductData: {
        product_sku_code: '',
        items_stock: 0,
        item_price: self.product.value,
        price_by_weight: false,
        item_saleable: true,
        weights: ''
      },
      productData: {}
    }
  },

  mounted() {
    if (this.isForm) {
      if (this.isEdit) {
        this.productData = {
          product_sku_code: this.product.sku_code,
          items_stock: this.product.stock,
          item_price: this.product.price,
          price_by_weight: false,
          item_saleable: this.product.saleable,
          weights: ''
        }
      } else {
        this.productData = clonedeep(this.defaultProductData)
        this.productData.product_sku_code = this.product.sku_code
      }
    }
  },

  computed: {
    ...mapGetters({
      loadingProductsState: 'store/loadingProductsState',
    }),
  },

  methods: {
    edit() {
      EventBus.$emit('edit-store-product', this.product)
    }
  },

  watch: {
    productData: {
      handler: function (data) {
        console.log('data ', data)
        this.$emit('update:data', data)
      },
      deep: true
    },
    'productData.items_stock': {
      handler: function (v) {
        this.productData.item_saleable = v > 0;
      },
      deep: true
    }
  }
}
</script>

<style scoped>

</style>