<template>
  <dialog-form :title="$t('ecommerce.stores.options-edit.title')"
               dialog-id="store-options-form"
               :valid="valid"
               :is-loading="isPatchingStore"
               :dialog.sync="dialog"
               @save="update"
               @close="close"
  >
    <v-form
        v-if="showForm"
        ref="form"
        v-model="valid"
        lazy-validation
    >
      <v-row>

        <v-col>
          <v-switch
              class="mt-1"
              v-model="storeOptions.click_and_collect"
              :label="$t('ecommerce.stores.details.click_and_collect')"
              inset
              color="success"
          ></v-switch>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6">
          <v-combobox multiple
                      v-model="storeOptions.valid_caps"
                      :label="$t('stores.fields.valid_caps')"
                      append-icon
                      outlined
                      small-chips
                      deletable-chips
                      class="tag-input"
                      :search-input.sync="search"
                      @keyup.tab="updateCaps"
                      @paste="updateCaps">
          </v-combobox>
        </v-col>

        <v-col cols="6">
          <v-combobox multiple
                      v-model="storeOptions.cc_emails"
                      :label="$t('stores.fields.cc_emails')"
                      append-icon
                      outlined
                      small-chips
                      deletable-chips
                      class="tag-input"
                      :search-input.sync="search"
                      @keyup.tab="updateCaps"
                      @paste="updateCaps">
          </v-combobox>
        </v-col>
      </v-row>
    </v-form>
  </dialog-form>
</template>

<script>

import DialogForm from "@/views/Layout/_partials/forms/DialogForm";
import clonedeep from 'lodash.clonedeep'
import {mapGetters} from "vuex";

export default {
  name: "EditOptions",

  components: {
    DialogForm
  },

  data() {
    return {
      showForm: false,
      storeOptions: {},
      valid: true,
      dialog: false,
      search: "" //sync search
    }
  },

  mounted() {
    this.storeOptions = clonedeep(this.store)
    this.showForm = true
  },

  computed: {
    ...mapGetters({
      isAdmin: 'auth/isAdmin',
      isPatchingStore: 'store/isPatchingStore',
      store: 'store/getStore'
    }),
  },

  methods: {

    updateCaps() {
      this.$nextTick(() => {
        this.select.push(...this.search.split(","));
        this.$nextTick(() => {
          this.search = "";
        });
      });
    },

    async update() {
      await this.$store.dispatch('store/updateStore', this.storeOptions)
          .then(resp => {
            console.log('then close dialog resp ', resp)
            this.close()
          })
          .catch(err => {
            console.log('create error err ', err)
          })
    },

    close() {
      this.dialog = false
      this.valid = true
      this.$refs.form.reset()
      this.$nextTick(() => {
        this.storeOptions = clonedeep(this.store)
      })
    },
  }


}
</script>

<style scoped>

</style>