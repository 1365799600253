var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"mt-5"},[_c('product-form')],1),_c('v-divider'),_c('v-card',{staticClass:"mt-5 d-flex",attrs:{"flat":""}},[_c('v-text-field',{staticClass:"mr-5",attrs:{"append-icon":"mdi-magnify","label":_vm.$t('commons.actions.search'),"outlined":"","dense":""},model:{value:(_vm.search.text),callback:function ($$v) {_vm.$set(_vm.search, "text", $$v)},expression:"search.text"}}),_c('v-select',{attrs:{"label":_vm.$t('commons.fields.status'),"items":_vm.saleableStatuses,"item-text":"name","item-value":"value","outlined":"","dense":""},model:{value:(_vm.search.saleable),callback:function ($$v) {_vm.$set(_vm.search, "saleable", $$v)},expression:"search.saleable"}})],1),_c('v-data-table',{staticClass:"elevation-0 clickable",attrs:{"loading":_vm.loadingProductsState,"loading-text":_vm.$t('commons.loading'),"headers":_vm.headers,"page":_vm.page,"items":_vm.products,"items-per-page":_vm.itemsPerPage,"hide-default-footer":true,"expanded":_vm.expanded,"show-expand":"","single-expand":true,"search":_vm.search.text},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event},"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('b',[_vm._v(_vm._s(item.name))])]}},{key:"item.saleable",fn:function(ref){
var item = ref.item;
return [_c('b',[_c('check',{attrs:{"val":item.saleable}})],1)]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$n(item.price, 'currency'))+" ")]}},{key:"item.image",fn:function(ref){
var item = ref.item;
return [(item.image)?_c('v-img',{staticClass:"py-5",attrs:{"src":item.image.url,"contain":"","width":"100"}}):_vm._e()]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-container',{staticClass:"grey lighten-5"},[_c('product-details',{attrs:{"product":item}})],1)],1)]}}])}),_c('v-divider'),_c('div',{staticClass:"text-center py-3"},[_c('v-pagination',{attrs:{"length":_vm.pageCount},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }