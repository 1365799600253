<template>
  <v-card class="mt-10">
    <v-card-text>
      <v-row>
        <v-col>
          <strong>{{ $t('ecommerce.stores.details.title') }}</strong>
          <divider></divider>
          <v-chip label :color="(store.click_and_collect) ? 'success' : 'error'">
            {{
              (store.click_and_collect) ? $t('ecommerce.stores.details.enabled.label') : $t('ecommerce.stores.details.disabled.label')
            }}
          </v-chip>
          <br>
          {{
            (store.click_and_collect) ? $t('ecommerce.stores.details.enabled.text') : $t('ecommerce.stores.details.disabled.text')
          }}
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <strong>{{ $t('stores.fields.valid_caps') }}:</strong>
          <divider></divider>
          <template v-if="Object.keys(store.valid_caps).length > 0">
            <v-chip label color="primary" class="mr-3 mb-3" v-for="cap in store.valid_caps" :key="cap">{{
                cap
              }}
            </v-chip>
          </template>
          <span v-else>-</span>
        </v-col>

        <v-col>
          <strong>{{ $t('stores.fields.cc_emails') }}:</strong>
          <divider></divider>
          <template v-if="Object.keys(store.cc_emails).length > 0">
            <v-chip label color="primary" class="mr-3 mb-3" v-for="email in store.cc_emails" :key="email">{{
                email
              }}
            </v-chip>
          </template>
          <span v-else>-</span>
        </v-col>

      </v-row>
      <v-divider></v-divider>
    </v-card-text>
    <v-card-actions class="d-flex justify-end">
      <edit-options></edit-options>
    </v-card-actions>
  </v-card>
</template>

<script>

import {mapGetters} from "vuex";
import EditOptions from "@/components/stores/_partials/EditOptions";

export default {
  name: "Options",

  components: {
    EditOptions
  },

  computed: {
    ...mapGetters({
      isAdmin: 'auth/isAdmin',
      isLoading: 'store/loadingState',
      store: 'store/getStore'
    }),
  },
}
</script>

<style scoped>

</style>